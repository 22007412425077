<template>
  <div class="container toefl-list">
    <ToeflMenu active="5" />
    <div class="toefl-header">
      <PageTitle>
        <div v-if="isNew === 2">
          {{ $t("toefl.index.title_new") }}
        </div>
        <div v-if="isNew === 1">
          {{ $t("toefl.index.title_old") }}
        </div>
      </PageTitle>
      <div class="change-version">
        <el-button
          v-if="isNew === 2"
          @click="changeVersion(1)"
          style="width:auto"
          ><i class="el-icon-refresh"></i>
          {{ $t("toefl.index.switch_old") }}</el-button
        >
        <el-button
          v-if="isNew === 1"
          @click="changeVersion(2)"
          style="width:auto"
          ><i class="el-icon-refresh"></i>
          {{ $t("toefl.index.switch_new") }}</el-button
        >
      </div>
    </div>
    <ToeflListTitle
      :testType="testType"
      :singleType.sync="singleType"
      :isNew="isNew"
      :isBrowse="true"
    />
    <ToeflListBody
      countryCode="TW"
      :testType="testType"
      :isNew="isNew"
      :isBrowse="true"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import PageTitle from "@/components/PageTitle.vue";
import ToeflMenu from "@/views/toefl/components/ToeflMenu.vue";
import ToeflListTitle from "@/views/toefl/toeflList/ToeflListTitle";
import ToeflListBody from "@/views/toefl/toeflList/ToeflListBody";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Browse - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    ToeflMenu,
    ToeflListTitle,
    ToeflListBody
  },

  mixins: [],

  props: [],
  data() {
    return {
      cardsTotal: 0,
      isNew: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    testType() {
      let testType = "reading";
      if (this.$route.query.testType === undefined) {
        testType = "reading";
      } else {
        testType = this.$route.query.testType;
      }
      return testType;
    },
    singleType() {
      if (this.$route.query.testType == "quick") {
        return this.$route.query.singleType;
      }
      return "";
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.query.isNew === undefined) {
      this.isNew = parseInt(await this.getVersion());
    } else {
      this.isNew = parseInt(this.$route.query.isNew);
    }
  },

  methods: {
    async getVersion() {
      const res = await TOEFL.getMetaData();
      if (res && res.version) {
        return res.version;
      } else {
        return 1;
      }
    },
    async changeVersion(val) {
      await TOEFL.postMetaData({
        metadata: {
          version: val
        }
      });
      this.routerPush({
        name: "Browse",
        query: {
          testType: this.testType,
          isNew: val
        }
      });
    }
  }
};
</script>
<style scoped>
.toefl-header {
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.toefl-list {
  margin-bottom: 10px;
}

.toefl-link {
  font-size: 14px;
}
@media screen and (max-width: 836px) {
  .toefl-header {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 736px) {
  .toefl-link {
    margin-top: 10px;
    font-size: 14px;
    display: block;
  }
  .change-version {
    display: flex;
  }
  ::v-deep .change-version .el-button {
    width: auto;
  }
}
@media screen and (max-width: 400px) {
  ::v-deep .change-version .el-button {
    width: 100%;
  }
}
</style>
